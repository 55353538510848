import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import SlipFour from "../elements/split/SlipFour";
import TimelinePenal from "../elements/timeline/TimelinePenal";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";
import HeaderTopBar from '../common/header/HeaderTopBar';
import BlogClassicData from '../data/blog/BlogList.json';
import BrandTwo from '../elements/brand/BrandTwo';
import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';
var BlogListData = BlogClassicData.slice(0, 3);


const InternationalConsulting = () => {
    return (
        <>
            <SEO title="Derecho Penal" />
            <main className="page-wrapper">
            <HeaderTopBar /> 
            <HeaderOne btnStyle="btn-small" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="5" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-18.jpeg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="title display-one"> <span className="color-border">Derecho</span> <span className="color-border">Penal</span>.</h1>
                                    <h2 className="description color-dark">Te <span className="color-border">acompañamos</span>  en las etapas de denucia, juicio y sentencia hasta <span className="color-border">coseguir tu tranquilidad</span>  </h2>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium btn-icon round" to="#">Agenda tu Asesosría<i className="icon"><FiArrowRight /></i></Link>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}
                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "No dejes pasar más tiempo"
                                    title = "Nosotros te ayudamos"
                                    description = ""
                                />
                            </div>
                        </div>
                        <TimelinePenal/>
                    </div>
                </div>
                {/* End Elements Area  */}
                {/* Start Elements Area  */}
                <div className="rwt-split-area">
                    <div className="wrapper">
                        <SlipFour />
                    </div>
                </div>
                {/* End Elements Area  */}
                {/* Empieza componente de marca */}
                <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Descubre"
                                        title = "Nos especializamos en"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandTwo brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                </div>
                {/* Termina componente de marca  */}
                
                <Separator />
                {/* Start Elements Area  */}

                <CalltoActionTwo />
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default InternationalConsulting
