import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
    {
        image: 'testimonial_1',
        name: 'Isela Rodriguez',
        designation: 'Cliente',
        location: 'Ciudad de México', 
        description: '¡Excelente servicio! Abogados 100% profecionales,  atencion de calidad e inmediata. Despejan todas las dudas y siempre trabajan en beneficio del cliente, brindan un servicio de primera. ¡Totalmente recomendable!',
       
    },
    {
        image: 'testimonial_2',
        name: 'Diego Malva',
        designation: 'Cliente',
        location: 'Ciudad de México', 
        description: 'Estuve buscando buenos abogados porque la verdad ninguno me daba confianza y siendo la primera vez que necesitaba a uno. El abogado Isidro sin duda uno de los mejores, te explica punto por punto el paso a dar, te da recomendaciones y es muy atento a todo el proceso al caso ¡Lo recomiendo al 100 %!',
      
    },
    {
        image: 'testimonial_3',
        name: 'Ricardo Olvera',
        designation: 'Cliente',
        location: 'Ciudad de México', 
        description: 'Muy buen servicio, he tratado con muchos despachos y sin duda este ha sido uno de los mejores, tienen un gran equipo de abogados que sin duda no durarán en ayudarte. Los recomiendo al 100%',
    },
]


const TestimonialOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15 ">
            {testimonialData.map((data, index) => (
                <div className={`${column}` } key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-box-card bg-theme-gradient ${teamStyle}`}>
                            <div className="inner ">
                                <figure className="thumbnail">
                                    <img src={`./images/testimonial/${data.image}.jpg`} alt="Imagen de un cliente en bufete jurídico miguel y asociados BJMA" />
                                </figure>
                                <figcaption className="content">
                                    <p className="description color-dark">{data.description}</p>
                                    <h2 className="title color-pink-darker">{data.name}</h2>
                                    <h6 className="subtitle color-border">{data.designation}</h6>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TestimonialOne;
