import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";
import Logot from "../../assets/images/logo/logo.png"

const Logo = ({image, image2}) => {
    const logo_dark = require("../../assets/images/logo/logo.png").default;
    const logo_light = require("../../assets/images/logo/logo-light.png").default;
    return(
        <div className="logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img className="logo-light" src={ logo_dark } alt="Corporate Logo" />
                <img className="logo-dark" src={ logo_dark } alt="Corporate Logo" />
            </Link>
        </div>
    )
}
Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
