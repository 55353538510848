import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "Servicio 1",
        title: "Asesoría Legal",
        description: "Conocemos su asunto legal y brindamos una breve asesoría",
        image: "timeline-01",
        alt:"Sala de reuniones con tres cuatro personas hablando sobre temas legales",
        workingStep: [
            {
                stepTitle: "Narración de hechos",
                stepDescription: "Usted nos narrará su situación legal para crear una linea de tiempo de los hechos susitados",
            },
            {
                stepTitle: "Entrega de documentación",
                stepDescription: "Recibimos su documentación y la resguardamos para analizar su situación legal",
            },
        ]
    },
    {
        id: "2",
        date: "Servicio 2",
        title: "Apoyo Legal",
        description: "Si requiere de un abogado en estos momentos puede contactarnos",
        image: "timeline-02",
        alt:"Persona siendo arrastrada por un grupo de policias aparentemente para arrestarla",
        workingStep: [
            {
                stepTitle: "Detención Ilegal",
                stepDescription: "Si necesitas ayuda en caso de que un familiar o conocido haya sido dentenido",
            },
            {
                stepTitle: "Embargo",
                stepDescription: "En caso de requerir asistencia técnica por embargo",
            },
            {
                stepTitle: "Realizar una Denuncia",
                stepDescription: "En caso de requerir asistencia pará denunciar un delito",
            },
        ]
    },
    {
        id: "3",
        date: "Servicio 3",
        title: "Defensa en un Juicio",
        description: "Iniciaremos el procedimiento o continuaremos con el jucio que haya iniciado en materia civil, penal, laboral, mercantil, etc.",
        image: "timeline-03",
        alt:"Sala de audiencia en donde tres personas interrogan a una persona",
        workingStep: [
            {
                stepTitle: "Recabación de medios probatorios",
                stepDescription: "Estaremos buscando las mejores herramientas para brindrar un caso exitoso a juicio",
            },
            {
                stepTitle: "Acompañamiento en el proceso",
                stepDescription: "Estaremos a su lado en cada etapa del proceso, guiando y asesorando siempre que lo necesite",
            },
        ]
    },
    {
        id: "2",
        date: "Servicio 4",
        title: "Justicia Alternativa",
        description: "Acompañamiento y asesoría en algun medio alternatino de conflicto",
        image: "timeline-04",
        alt:"Dos mujeres en una oficina firmando y revisando un par de documentos",
        workingStep: [
            {
                stepTitle: "Asesoría",
                stepDescription: "En caso de optar por algun medio alternativo de conflicto, le brindaremos herramientas para que tenga un buen desenlace",
            },
            {
                stepTitle: "Acompañamiento",
                stepDescription: "Si en algun momento del procedimiento alternativo de conflicto usted requiere la presencia de un abogado, estaremos listos para brindarle nuestra ayuda",
            },
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h1 className="date-of-timeline color-dark">{data.date}</h1>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h1 className="title color-border">{data.title}</h1>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h5 className="description color-dark">{data.description}</h5>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title color-border">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h6 className='color-dark'>{data.stepDescription}</h6>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="read-morebtn text-center">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-border btn-extra-large btn-large round" href="https://api.whatsapp.com/message/6IYWAD3HS6JLJ1?autoload=1&app_absent=0" target="_blank"><span>Agende tu Cita</span></a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.jpg`} alt={data.alt} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
