import React from 'react';

const BrandList = [
    {
        image: './images/brand/brand-01.png',
        url:'https://www.tfja.gob.mx/',
        alt:"logotipo del tribunal federal de justicia administrativa",
    },
    {
        image: './images/brand/brand-02.png',
        url:'http://www.cndh.org.mx/',
        alt:"logotipo de la cominsión nacioanl de derecho humanos"
    },
    {
        image: './images/brand/brand-03.png',
        url:'https://www.poderjudicialcdmx.gob.mx/',
        alt:"logotipo del poder jucial de la ciudad de méxico"
    },
    {
        image: './images/brand/brand-04.png',
        url:'https://www.cjf.gob.mx/',
        alt:"logotipo del consejo de la judicatura federal"
    },
    {
        image: './images/brand/brand-05.png',
        url:'http://www.imss.gob.mx/',
        alt:"logotipo  del instituto mexicano del seguro social"
    },
    {
        image: './images/brand/brand-06.png',
        url:'https://www.gob.mx/issste',
        alt:"logotipo del instituto de seguridad y servicio social de los trabajadores del estado"
    },
    {
        image: './images/brand/brand-07.png',
        url:'https://www.gob.mx/conamed',
        alt:"logotipo de la comision nacional de arbitraje médico"
    },
    {
        image: './images/brand/brand-08.png',
        url:'https://www.gob.mx/profeco',
        alt:"logotipo de la procuraduria federal del consumidor"
    },
    {
        image: './images/brand/brand-09.png',
        url:'https://www.gob.mx/impi',
        alt:"logotipo del instituto mexicano de la propiedad industrial"
    },
    {
        image: './images/brand/brand-10.png',
        url:'https://www.fgjcdmx.gob.mx/',
        alt:"logotipo de la fiscalía general de justicia de la ciudad de méxico"
    },
    {
        image: './images/brand/brand-11.png',
        url:'https://www.gob.mx/fgr',
        alt:"logotipo de la fiscalía general de la republica"
    },
    {
        image: './images/brand/brand-12.png',
        url:'https://www.pjedomex.gob.mx/vista/1_inicio',
        alt:"logotipo del poder judicial del estado de méxico"
    },
]

const BrandTwo = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href={`${data.url}`} target="_blank"><img src={`${data.image}`} alt={`${data.alt}`}/></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandTwo;
