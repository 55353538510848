import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "Servicio 1",
        title: "Asistencia Penal ",
        title2:"(Urgencia)",
        description: "Si requieres un abogado de forma urgente contactanos",
        image: "timeline-penal-01",
        workingStep: [
            {
                stepTitle: "Detención Ilegal",
                stepDescription: "Si necesitas ayuda en caso de que un familiar o conocido haya sido dentenido",
            },
            {
                stepTitle: "Orden de Restricción",
                stepDescription: "Si sufres de violencia o acoso y temes por tu integridad",
            },
            {
                stepTitle: "Presentar una Denuncia",
                stepDescription: "Te ayudamos a realizar la denuncia de un delito",
            },
        ]
    },
    {
        id: "2",
        date: "Servicio 2",
        title: "Asesoría Penal",
        description: "Conocemos su situación legal y le brindaremos una estrategia legal",
        image: "timeline-penal-02",
        workingStep: [
            {
                stepTitle: "Analisis del Caso",
                stepDescription: "Esucharemos tu problematica y lo acomodaremos al delito al que has sido victima",
            },
            {
                stepTitle: "Estrategía Legal",
                stepDescription: "Te brindaremos la estrategía que podrás seguir para repar el daño",
            },
        ]
    },
    {
        id: "3",
        date: "Servicio 3",
        title: "Ministerio Público",
        description: "Te ayudaremos a realizar una denuncia y te acompañaremos hasta la judicialización de tu carpeta de investigación",
        image: "timeline-penal-03",
        workingStep: [
            {
                stepTitle: "Recabación de medios probatorios",
                stepDescription: "Estaremos buscando las mejores herramientas para brindrar un caso exitoso a juicio",
            },
            {
                stepTitle: "Acompañamiento en el proceso",
                stepDescription: "Estaremos a su lado en cada etapa del proceso, guiando y asesorando siempre que lo necesite",
            },
        ]
    },
    {
        id: "2",
        date: "Servicio 4",
        title: "Juez de Control",
        description: "Estaremos a tu lado en todo momento y defenderemos tus intereses",
        image: "timeline-penal-04",
        workingStep: [
            {
                stepTitle: "Juez de Control",
                stepDescription: "En ",
            },
            {
                stepTitle: "Sentencia",
                stepDescription: "",
            },
        ]
    },
    {
        id: "2",
        date: "Servicio 4",
        title: "Amparo",
        description: "Te brindamos nuestro apoyo al requerir un medio de defensa contra actos u omisiones de autoridad ",
        image: "timeline-penal-05",
        workingStep: [
            {
                stepTitle: "Estrategía",
                stepDescription: "Al acercarte a nosotros te guiaremos para resolver el conflicto que tienes",
            },
            {
                stepTitle: "Acompañamiento",
                stepDescription: "Si en algun momento del procedimiento alternativo de conflicto usted requiere la presencia de un abogado, estaremos listos para brindarle nuestra ayuda",
            },
        ]
    },
]

const TimelinePenal = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h1 className="date-of-timeline color-dark">{data.date}</h1>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h1 className="title color-dark">{data.title} <span className="color-pink-darker">{data.title2}</span> </h1>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h5 className="description color-dark">{data.description}</h5>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title color-pink-darker">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h6 className='color-dark'>{data.stepDescription}</h6>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="read-morebtn text-center">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-border btn-extra-large round" href="https://api.whatsapp.com/message/6IYWAD3HS6JLJ1?autoload=1&app_absent=0" target="_blank"><span>Agende tu Cita</span></a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.jpeg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelinePenal;
