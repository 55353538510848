import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import TabThree from '../elements/tab/TabThree';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import SlipFour from "../elements/split/SlipFour";
import SlpitOne from "../elements/split/SlpitOne";
import AdvancePricingOne from "../elements/advancePricing/AdvancePricingOne";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import HeaderTopBar from '../common/header/HeaderTopBar';
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import VideoOne from '../elements/video/VideoOne';
import CalltoActionFour from '../elements/calltoaction/CalltoActionFour';
import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';
var BlogListData = BlogClassicData.slice(0, 3);


const InternationalConsulting = () => {
    return (
        <>
            <SEO title="BJMA Derecho Mercantil" />
            <main className="page-wrapper">
            <HeaderTopBar /> 
            <HeaderOne btnStyle="btn-small" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="1" style={{backgroundImage: `url(https://images.unsplash.com/photo-1613293241506-523dcf0cb1bb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="title display-one"> <span className="theme-gradient">Derecho</span> <span className="theme-gradient">Mercantil</span>.</h1>
                                    <p className="description color-dark">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium btn-icon" to="#"> <i className="icon"><FiArrowRight /></i></Link>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "No dejes pasar más tiempo"
                                    title = "Esto es lo que necesitas"
                                    description = ""
                                />
                            </div>
                        </div>
                        <TabThree />
                    </div>
                </div>
                {/* End Elements Area  */}


                {/* Start Elements Area  */}
                <div className="rwt-split-area no-radius">
                    <div className="wrapper">
                        <SlpitOne />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-split-area">
                    <div className="wrapper">
                        <SlipFour />
                    </div>
                </div>
                {/* End Elements Area  */}


                <div className="main-content">
                    <div className="rwt-elements-area rn-section-gap">
                        <div className="container-fluid plr--30">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "conoce un poco más"
                                        title = "Areas en que podemos ayudarte"
                                        description = ""
                                    />
                                </div>
                                <VideoOne/>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Separator />
                {/* Start Elements Area  */}

                <CalltoActionTwo />
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default InternationalConsulting
