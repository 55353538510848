import React from 'react';
import VideoTwo from '../video/VideoTwo';


const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/embed/N0qDVluNxeQ',
        ],
    }
]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h1 className="text-center">Necesitas un <strong className='color-border'> abogado</strong>, nosotros <strong className='color-border' > te acompañamos</strong> en tu proceso </h1>
                                {/* <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h2 className="title">Comienza a obtener una <strong>solución</strong></h2>
                                            <p className="text">El <strong>97% de nuestros clientes</strong> obtien una <strong>solución</strong> a sus problemas legales</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title"><strong>Encuentra</strong> un Bufete de <strong>confianza</strong> </h4>
                                            <p className="text">No solo encontarás <strong>abogados profesionales</strong> que te <strong>ayudarán</strong>  con tus <strong>problemas legales</strong>, también <strong>contarás</strong> con <strong>profesionales</strong>  que te <strong>escuchen</strong>  y brinden un <strong>trado digno</strong>. </p>
                                        </div>
                                    </li>
                                </ul> */}
                                <div className="text-center about-btn mt--10">
                                    <a className="btn-default btn-large btn-border round" href="https://www.facebook.com/BufeteMiguelAsociados/services/?ref=page_internal" target="_blank">Agenda tu Cita</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
