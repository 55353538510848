import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar'; 
import { FiArrowRight } from "react-icons/fi";
import AboutFour from '../elements/about/AboutFour';
import TestimonialOne from '../elements/testimonial/TestimonialOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import TimelineOne from "../elements/timeline/TimelineOne";
import Copyright from '../common/footer/Copyright';
import BrandTwo from '../elements/brand/BrandTwo';
import FooterTwo from '../common/footer/FooterTwo';

const Consulting = () => {
    const PUBLIC_URL= "https://images.unsplash.com/photo-1642522029691-029b5a432954?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80";
    return (
        <>
            <SEO title=" Abogados BJMA" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-transparent" />

                {/* Empieza componente de Hero */}
                <div className="slider-area slider-style-2 variation-2  height-850 bg_image" data-black-overlay="6"  style={{backgroundImage: `url(${PUBLIC_URL})`}} alt="Hombre recargado en un escritorio brindrando asesoría legal a una persona a lado suyo">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="display-one color-dark"><span className='color-border'></span>Estas a un paso de <span className='color-border'>solucionar</span> tu <span className='color-border'>problema</span></h1>
                                    <h2 className="description color-dark"><span className='color-border'>¡Obtén</span> una Asesoría <span className='color-border'>Legal!</span></h2>
                                    <div className="button-group">
                                        <a className="btn-default btn-extra-large round btn-icon" target="_blank" href="https://www.facebook.com/BufeteMiguelAsociados/services/?ref=page_internal">Agenda tu Asesoría <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Termina componente de Hero */}

                <Separator />

                {/* Empieza componente de video */}

                <AboutFour image={require("../assets/images/video.jpeg").default} />

                {/* Termina componente de video */}

                {/* Empieza componente de conteo  */}
                {/* <div className="rwt-counterup-area rn-section-gapBottom mt_dec--30">
                        <div className="container">
                            <div className="inner text-center">
                                <span className="subtitle"></span>
                                <h1 className="title display-one color-primary"> Mira algunos <span>Resultados</span>.</h1>
                                <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                            </div>
                    </div>
                </div> */}
                {/* Termina componente de conteo */}

                {/* Empieza componente  */}
                    <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Conoce nuestros"
                                        title = "Servicios"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <TimelineOne/>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* End Brand Area  */}


                {/* Empieza componente de testimonios */}
                <div className="rwt-testimonial-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--10">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Lo que dicen nuestros clientes"
                                        title = "Testimonial."
                                    />
                                </div>
                            </div>
                            <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                        </div>
                    </div>
                {/* Termina componente de testimonios  */}

                <Separator />

                {/* Empieza componente de marca */}
                <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Descubre"
                                        title = "Nos especializamos en"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandTwo brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                </div>
                {/* Termina componente de marca  */}

                <Separator />

                <FooterTwo />
                <Copyright />
            </main>

        </>
    )
}

export default Consulting
