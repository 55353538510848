import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import ServiceOne from "../elements/service/ServiceOne";
import Services from "../elements/service/ServiceThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpOne from '../elements/counterup/CounterUpOne';
import CounterUpFour from "../elements/counterup/CounterUpFour";
import Separator from "../elements/separator/Separator";
import FooterTwo from '../common/footer/FooterTwo';

const AboutUs = () => {
    return (
        <>
            <SEO title="Nosotros BJMA" />
            <main className="page-wrapper">
            <HeaderTopBar />    
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="6" style={{backgroundImage: `url(https://images.unsplash.com/photo-1606836591695-4d58a73eba1e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Conoce un poco más de </span></h3>
                                    </div>
                                    <h1 className="title display-one color-dark">Bufete Jurídco Miguel <br />  y Asociados</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Somos un Bufete legal con el proposito de proteger tus intereses</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quis. Ullam accusantium dignissimos repellendus nemo fugiat numquam, nisi odio adipisci. Veniam neque itaque expedita officiis rem ipsa! Ratione, rem reiciendis?</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />          

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = ""
                                    title = "Nosotros"
                                    description = "Ofrecemos servicios legales a través de un grupo interdiciplinario, <br /> integrado por abogados con experiencia y trayectoria profesional. "
                                    />
                            </div>
                        </div>
                        <Services/>
                    </div>
                </div>
                {/* End Service Area  */}

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = "Visión"
                                        description = "Consolidar nuestro Bufete como uno de los más prestigiosos de México <br /> al ofrecer a nuestros clientes el mejor resultado."
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 icon-circle-style"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    {/* Empieza componente de tabla  */}
                    <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">   
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Descubre"
                                        title = "Nuestros Socios"
                                        description = ""
                                    />
                                    <CounterUpOne column="col-lg-12 col-md-12 col-sm-6 col-12" counterStyle="counter-style-1" textALign="text-center" />
                                </div>
                            </div>
                            <AdvanceTabOne />
                        </div>
                    </div>
                    {/* Empieza componente de tabla */}
                
                {/* Start Elements Area  */}
                <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />

                <FooterTwo/>
            </main>
        </>
    )
}

export default AboutUs;
