import React from 'react';

const TestimonialData = [ {
        id: "01",
        form: "Testimonio",
        description: "Excelente servicio, abogados 100% profecionales atencion de calidad e inmediata despejan todas las dudas y siempre trabajan en beneficio del cliente un servicio de primera totalmente recomendable.",
        name: "Isabel Rodriguez",
        subtitle: "Cliente",
        image: "testimonial_1",
    }

    
]

const TestimonialTwo = ({column , teamStyle}) => {
    return (
        <div className="row">
            <div className={`${column}`}>
                <div className={`testimonial-style-two ${teamStyle}`}>
                    <div className="row align-items-center row--20 color-primary">
                        <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                            <div className="content mt_sm--40">
                                <span className="form">{TestimonialData.form}</span>
                                <p className="description color-primary">{TestimonialData.description}</p>
                                <div className="client-info">
                                    <h4 className="title">{TestimonialData.name}</h4>
                                    <h6 className="subtitle color-primary">{TestimonialData.subtitle}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="order-1 order-md-2 col-lg-4 col-md-4">
                            <div className="thumbnail">
                                <img className="w-100" src={`./images/testimonial/${TestimonialData.image}.jpg`} alt="Corporate React Template" />
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        
    )
}
export default TestimonialTwo;