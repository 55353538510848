import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiChevronRight, FiMapPin, FiPhone } from "react-icons/fi";
import { a } from 'react-router-dom'

const HeaderTopBar = () => {
    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="header-left">
                            <p><a href="#link" target="_blank">Aquí puedes encontrarnos <FiChevronRight /></a></p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="header-right">
                            <div className="address-content">
                                <p><FiMapPin /><span><a href="https://g.page/bufetejma?share" target="_blank">Coyoacán, CDMX</a></span></p>
                                <p><FiPhone /><span><a href="tel:+525556037941"target="_blank" >55 56 03 79 41</a></span></p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    <li><a href="https://www.facebook.com/BufeteMiguelAsociados" target="_blank"><FiFacebook alt="Logotipo de Facebook" /></a></li>
                                    <li><a href="https://twitter.com/BufeteMiguel" target="_blank"><FiTwitter alt="Logotipo de Twitter"/></a></li>
                                    <li><a href="https://www.instagram.com/bjma_law/" target="_blank"><FiInstagram alt="Logotipo de Instagram"/></a></li>
                                    <li><a href="https://www.linkedin.com/in/bjma-miguel-y-asociados-b5bb37217/" target="_blank"><FiLinkedin alt="Logotipo de Linkedin"/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
