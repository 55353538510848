import React from 'react';
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Número de contacto</h4>
                                        <p><a href="tel:+52 55 56 03 79 41" target="_blank">55 56 03 79 41</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Correo Electrónico</h4>
                                        <p><a href="mailto:bjmajuridico@gmail.com" target="_blank">contacto@bjma.com.mx</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Localización</h4>
                                        <a href="https://g.page/bufetejma?share" target="_blank"><p>Santa Cecilia 97, <br /> Coyoacán, Ciudad de México</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-title text-center mt--40 row--15">
                <div className="col-lg-12 mt_md--20 mt_sm--20">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d844.4989654474036!2d-99.10869517082128!3d19.312209264826215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd44e66c775647255!2zMTnCsDE4JzQ0LjAiTiA5OcKwMDYnMjkuMyJX!5e1!3m2!1ses-419!2smx!4v1663703107422!5m2!1ses-419!2smx" width="520" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
                </div>
            </div>
        </>
    )
}
export default ContactOne;